import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import './App.css';
import Login from './auth_components/js/Login';
import HomePage from './Containers/HomePage';
import RouteLoading from './RouteLoading';
import IdCardPaymentContainer from './Containers/IdCardPaymentContainer';
import IdCardPaymentResponse from './Containers/IdCardPaymentResponse';
import IdCardPrintContainer from './Containers/IdCardPrintContainer';

const MasterContainer = React.lazy(() => import(`./Containers/MasterContainer`));
const SettingsContainer = React.lazy(() => import(`./Containers/SettingsContainer`));
const ProgramsContainer = React.lazy(() => import(`./Containers/ProgramsContainer`));
const FeesContainer = React.lazy(() => import(`./Containers/FeesContainer`));
const StudentContainer = React.lazy(() => import(`./Containers/StudentContainer`));
const EmployeeContainer = React.lazy(() => import(`./Containers/EmployeeContainer`));
const BackupContainer = React.lazy(() => import(`./Containers/BackupContainer`));
const AllNotificationContainer = React.lazy(() => import(`./Containers/AllNotificationContainer`));

class App extends Component {
  render() {
    return (
      <Suspense fallback={<RouteLoading />}>
        <Router history={history} >
          <div>
            <Switch>
              <Route path="/login" component={Login} />
              <Route exact path="/" component={HomePage} />

              <Route exact path="/settings" component={SettingsContainer} />
              <Route exact path="/backup" component={BackupContainer} />

              <Route exact path="/programs" component={ProgramsContainer} />
              <Route exact path="/programs/add" component={ProgramsContainer} />
              <Route exact path="/programs/edit" component={ProgramsContainer} />

              <Route exact path="/masters" component={MasterContainer} />
              <Route exact path="/masters/course" component={MasterContainer} />
              <Route exact path="/masters/course/add" component={MasterContainer} />
              <Route exact path="/masters/course/edit" component={MasterContainer} />

              <Route exact path="/masters/subjects" component={MasterContainer} />
              <Route exact path="/masters/subjects/add" component={MasterContainer} />
              <Route exact path="/masters/subjects/edit" component={MasterContainer} />

              <Route exact path="/masters/access" component={MasterContainer} />
              <Route exact path="/masters/access/add" component={MasterContainer} />
              <Route exact path="/masters/access/edit" component={MasterContainer} />

              <Route exact path="/masters/adminuser" component={MasterContainer} />
              <Route exact path="/masters/adminuser/add" component={MasterContainer} />
              <Route exact path="/masters/adminuser/edit" component={MasterContainer} />

              <Route exact path="/masters/fees" component={MasterContainer} />
              <Route exact path="/masters/fees/add" component={MasterContainer} />
              <Route exact path="/masters/fees/edit" component={MasterContainer} />

              <Route exact path="/masters/feessession" component={MasterContainer} />
              <Route exact path="/masters/feessession/add" component={MasterContainer} />
              <Route exact path="/masters/feessession/edit" component={MasterContainer} />


              <Route exact path="/students" component={StudentContainer} />
              <Route exact path="/students/update-rollno" component={StudentContainer} />
              <Route exact path="/studentsall/reports" component={StudentContainer} />
              <Route exact path="/students/:course_code/:term" component={StudentContainer} />

              <Route exact path="/employees" component={EmployeeContainer} />
              <Route exact path="/employees/all" component={EmployeeContainer} />
              <Route exact path="/employees/leavestatus" component={EmployeeContainer} />
              <Route exact path="/employees/leavereport" component={EmployeeContainer} />


              <Route exact path="/idcardprint" component={IdCardPrintContainer} />
              <Route exact path="/idcardpayment" component={IdCardPaymentContainer} />
              <Route exact path="/idcardpaymentresponse" component={IdCardPaymentResponse} />
              <Route exact path="/fees" component={FeesContainer} />
              <Route exact path="/fees/student" component={FeesContainer} />
              <Route exact path="/fees/receipts" component={FeesContainer} />
              <Route exact path="/fees/response" component={FeesContainer} />
              <Route exact path="/fees/receipts/:receipt_no" component={FeesContainer} />

              <Route exact path="/notifications" component={AllNotificationContainer} />
              <Route exact path="/notifications/add" component={AllNotificationContainer} />


            </Switch>
          </div>
        </Router>
      </Suspense>
    )

  }
}

export default App;
