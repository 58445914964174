import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { Alert } from 'react-bootstrap';
import { Button, Container } from '@material-ui/core';
export default function IdCardPaymentResponse(props) {

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [receiptNo, setReceiptNo] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const [showReceipt, setShowReceipt] = useState(false);

    useEffect(() => {

        let query = queryString.parse(props.location.search)

        setStatus(query.status);
        setMessage(query.message);
        setReceiptNo(query.receipt_no);
        setIsLoaded(true);
    })


    useEffect(() => {
        if (isLoaded && status == 'Success' && receiptNo != '' && receiptNo != undefined) {
            setShowReceipt(true);
        }
    }, [receiptNo])

    return (
        <div>
            <Container>
                <Alert variant={status === 'Success' ? 'success' : 'danger'}>
                    <Alert.Heading>{status}</Alert.Heading>
                    {message}
                    <br />
                    <br />
                    {showReceipt && <Button variant="contained" target="_blank" href={`/fees/receipts/${receiptNo}`}>Print</Button>}
                </Alert>
            </Container>
        </div>
    )
}
