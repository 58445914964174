import React from 'react'
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: '#343A40'
    },
}));


export default function RouteLoading() {
    let classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
