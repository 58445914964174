import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { Typography } from '@material-ui/core'

export default class Loader extends Component {
  render() {
    return (
      <div>
        <Alert
          variant="success"
        >
          <Typography
            variant="caption"
          >
            {this.props.children}
          </Typography>
          
        </Alert>
      </div>
    )
  }
}
