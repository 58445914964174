import React, { Component } from 'react';
import AuthService from './AuthService';
export default function withAuth(AuthComponent) {
    const Auth = new AuthService('http://localhost/aec-backend/php-backend/public');
    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null
            }
        }
        componentWillMount() {
            if (!Auth.loggedIn()) {
                this.props.history.replace('/login');  
            }
            else {
                try {

                    // check if admin
                    const profile = Auth.getProfile()

                    const role = profile.user.role;


                    
                    this.setState({
                        user: profile,
                        role: role
                    })
                }
                catch(err){
                    Auth.logout()
                    this.props.history.replace('/login')
                }
            }
        }

        render() {
            if (this.state.user) {
                return (
                    <div>
                        <AuthComponent history={this.props.history} user={this.state.user} role={this.state.role} />
                    </div>
                )
            }
            else {
                return null
            }
        }
    };
}

