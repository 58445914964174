import { Button } from '@material-ui/core';
import React, { Component } from 'react'
import { GetData } from '../../api/service';
import moment from 'moment'

import ReactToPrint from 'react-to-print';

export default class IdCardPrint extends Component {
    state = {
        id: '',
        student: {},
        isLoaded: false,
        photo: '',
        paymentDone: null

    }


    getPhoto = (files) => {
        if (Array.isArray(files)) {
            let r = files.filter((el) => el.file_type == 'STUDENT_PHOTO')
            if (r.length > 0) {
                return r[0]['file_path']
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    getGoodClass = (course_code, term) => {
        if (course_code == "HSA" || course_code == "HSS" || course_code == "HSC") {
            if (term == 1) {
                return course_code + " 1st Year";
            } else if (term == 2) {
                return course_code + " 2nd Year";
            }
        } else {
            if (term == 1) {
                return course_code + " 1st Semester";
            } else if (term == 2) {
                return course_code + " 2nd Semester";
            } else if (term == 3) {
                return course_code + " 3rd Semester";
            } else {
                return `${course_code} ${term}th Semester`;
            }
        }
    }

    componentDidMount() {
        let id = this.props.student_id;
        if (id != undefined) {

            GetData(`/${id}/checkifidpaymentdone`)
                .then((resp1) => {
                    if (resp1 == true) {
                        this.setState({
                            paymentDone: true
                        })
                        GetData(`/${id}/getstudentbyid`)
                            .then((resp) => {
                                console.log("🚀 ~ file: IdCardPrint.js ~ line 18 ~ IdCardPrint ~ .then ~ resp", resp)
                                //console.log(resp)
                                this.setState({
                                    isLoaded: true,
                                    student: resp,
                                    photo: this.getPhoto(resp.studentFiles),
                                    id: id
                                })
                            })
                    }else{
                        this.setState({
                            paymentDone: false
                        })
                    }
                })


        } else {
            this.props.history.goBack();
        }
    }



    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '100vh' }}>

                {this.state.paymentDone == false && <div>Payment not done for this student</div>}

                {this.state.isLoaded && <div>
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <Button variant='contained' >Print Now</Button>
                        }}
                        content={() => this.componentRef}
                    />
                    <br />


                    <div ref={el => (this.componentRef = el)} style={{display: 'flex', padding: '7px'}}>
                        <div style={{margin: '3px', marginRight: '10px'}}>
                        <div style={{ width: '105mm', height: '74mm', border: 'solid 2px black', backgroundColor: '#FEE9B4' }}>
                            <div style={{ backgroundColor: '#DA3C1F', height: '80px', position: 'relative' }}>
                                <div><img src="/rangia-ic-header.jpg" style={{ width: '100%', height: '160px' }} /></div>
                                <img
                                    src={this.state.photo}
                                    style={{ width: '104px', height: '100px', position: 'absolute', top: '54px', left: '146px', border: 'solid thin black' }}
                                />
                                <div style={{ position: 'absolute', top: 80, right: 20, fontSize: 9, fontWeight: 700, textAlign: 'center', color: 'white', lineHeight: 1 }}>DATE OF ISSUE<br />{moment().format("DD-MM-YYYY")}</div>
                                <div style={{ position: 'absolute', top: '155px', width: '100%' }}>
                                    <div style={{ textAlign: 'center', fontSize: 12, fontWeight: 700 }}>{this.state.student.name}</div>
                                    <div style={{ textAlign: 'center', fontSize: 9, fontWeight: 700, marginTop: -3 }}>{this.state.student.roll_no}</div>

                                    <div style={{ paddingLeft: '20px' }}>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td width="50%"><div style={{ fontSize: 9, fontWeight: 700 }}>C/o: {this.state.student.father_name}</div></td>
                                                    <td width="50%"><div style={{ fontSize: 9, fontWeight: 700 }}>Class: {this.getGoodClass(this.state.student.course_code, this.state.student.term)}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div style={{ fontSize: 9, fontWeight: 700 }}>DOB: {moment(this.state.student.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")}</div></td>
                                                    <td><div style={{ fontSize: 9, fontWeight: 700 }}>Contact: {this.state.student.phone}</div></td>
                                                </tr>
                                                <tr>
                                                    <td ><div style={{ fontSize: 9, fontWeight: 700 }}>Address: {this.state.student.village}</div></td>
                                                    <td><div style={{ fontSize: 9, fontWeight: 700 }}>PO: {this.state.student.post_office}</div></td>
                                                </tr>
                                                <tr>
                                                    <td> <div style={{ fontSize: 9, fontWeight: 700 }}>Pin: {this.state.student.pin}</div></td>
                                                    <td><div style={{ fontSize: 9, fontWeight: 700 }}>District: {this.state.student.district},{this.state.student.state}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                    <div style={{ paddingLeft: '20px' }}>
                                        <table width="100%">
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: 'left' }}><div style={{ fontSize: 9, fontWeight: 700 }}>
                                                        <br />
                                                        Signature of the Student
                                                    </div></td>
                                                    <td style={{ textAlign: 'right', paddingRight: 10 }}><div style={{ fontSize: 9, fontWeight: 700, position: 'relative' }}>
                                                        <img
                                                            src="/rc-009.png"
                                                            style={{ width: '80px', position: 'absolute', top: -30, right: -20 }}
                                                        />
                                                        <br />
                                                        Principal
                                                    </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>






                                </div>
                            </div>

                        </div>
                        </div>
                        
                        <div style={{margin: '3px'}}>
                        <div style={{ width: '105mm', height: '74mm', border: 'solid 2px black', padding: 10, position: 'relative' }}>
                            <div style={{ border: 'solid 1px grey', height: 70, marginBottom: 10 }}></div>
                            <div style={{ border: 'solid 1px grey', height: 125, padding: 10 }}>
                                <div style={{ textAlign: 'center', fontSize: 9, fontWeight: 700 }}>INSTRUCTIONS</div>
                                <ul style={{ fontSize: 7, fontWeight: 700 }}>
                                    <li >EVERY STUDENT MUST POSSESS THIS CARD AND PRODUCE ON DEMAND.</li>
                                    <li>IN THE EVENT OF LOSS OF THE CARD DUPLICATE WILL BE ISSUED ON PAYMENT OF RS. 100/-.</li>
                                    <li>PLEASE KEEP THIS CARD IN SAFE CUSTODY. THE LIBRARY WILL NOT BE HELD RESPONSIBLE.</li>
                                    <li>FOR ANY MISUSE OF THIS CARD. THE LOSS OF THE CARD MUST BE REPORTED TO THE COLLEGE AUTHORITY IMMEDIATELY.</li>
                                    <li>LIBRARY BOOKS ARE ISSUED FOR 15 DAYS ONLY. A FINE AS PER LIBRARY RULES WILL BE CHARGED FOR EACH VOLUME KEPT BEYOND THE DUE DATE.</li>
                                    <li>BOOK LOST, TURN OR DEFACED IN ANYWAY WILL HAVE TO BE PAID FOR BY THE BORROWERS. </li>
                                    <li>THIS CARD IS NOT TRANSFERABLE</li>
                                </ul>
                            </div>
                            <div>
                                <img src="/footer-rangia-back.jpg" style={{ width: '100%' }} />
                            </div>
                            <div style={{ position: 'absolute', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <img src="/icon512.png" style={{ width: '50%', opacity: 0.3 }} />
                            </div>
                        </div>
                        </div>
                    </div>

                </div>}
            </div>
        )
    }
}
