import React, { Component } from 'react';
import withAuth from './../auth_components/js/withAuth';
import AuthService from './../auth_components/js/AuthService';
import { Link } from 'react-router-dom'

import './css/homepage.css';
import { Card, CardContent, Container, Grid, Typography, CardActionArea, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { lightGreen, lightBlue, blue, grey } from '@material-ui/core/colors';
import Accessibility from '@material-ui/icons/Accessibility'
import Pets from '@material-ui/icons/Pets'
import Dvr from '@material-ui/icons/Dvr'
import Person from '@material-ui/icons/Person'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import AddAlert from '@material-ui/icons/AddAlert'
import BugReport from '@material-ui/icons/BugReport'
import CloudDownload from '@material-ui/icons/CloudDownload'


const styles = theme => ({
  root: {
    minHeight: '100vh',
    position: 'fixed',
    width: '100vw',
    backgroundImage: `url(${require('./../assets/aec-banner-main.jpg')})`,
    backgroundRepeat: 'repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'fit',
    
    //background: `#232526`, /* fallback for old browsers */
    //background: `-webkit-linear-gradient(to right, #232526, #414345)`, /* Chrome 10-25, Safari 5.1-6 */
    //background: `linear-gradient(to right, #232526, #414345)` /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  },
  groundWhite: {
    marginTop: '50vh',
    backgroundColor: 'white',
    position: 'relative',
    minHeight: '60vh',
    
  },
  bubbles: {
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '-40px',
  },
  cardStyle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

  },
  cardImage: {
    width: '40%'
  },
  cardContainerOne: {
    flexBasis: '30%',
    textAlign: 'center'
  },
  cardContainerTwo: {
    flexBasis: '100%',
    textAlign: 'center',
    height: '60vh',
    marginTop: '-80px'

  }
})

const modules = [
  {
    title: 'Masters',
    path: '/masters/course',
    icon: <Pets style={{ fontSize: 40 }} />
  },
  {
    title: 'Students',
    path: '/students',
    icon: <Person style={{ fontSize: 40 }} />
  },
  {
    title: 'Fees Payment',
    path: '/fees',
    icon: <Dvr style={{ fontSize: 40 }} />
  },
  {
    title: 'Employee',
    path: '/employees',
    icon: <SupervisorAccount style={{ fontSize: 40 }} />
  },
  {
    title: 'Notifications',
    path: '/notifications',
    icon: <AddAlert style={{ fontSize: 40 }} />
  },
  {
    title: 'Backup',
    path: '/backup',
    icon: <CloudDownload style={{ fontSize: 40 }} />
  }
  
]


const modulesAdmin = [
 
  // {
  //   title: 'Students',
  //   path: '/students',
  //   icon: <Person style={{ fontSize: 40 }} />
  // },
  {
    title: 'Fees Payment',
    path: '/fees',
    icon: <Dvr style={{ fontSize: 40 }} />
  },
  
]

class HomePage extends Component {
  state = {
    version: ''
  }
  Auth = new AuthService();

  logout = () => {
    this.Auth.logout();
    this.props.history.replace('/login')
  }

  componentDidMount() {
    fetch(`/meta.json`)
    .then(resp => resp.json())
    .then((resp) => {
      this.setState({
        version: resp.version
      })
    })

    


  }

  
  


  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>

        <div className={classes.groundWhite}>
          <Container className={classes.bubbles}>
            {this.Auth.getRole() == "SUPERADMIN" && <Grid container spacing={2} >
              {modules.map((el, index) =>
                <Grid key={index} item >
                  <Link to={el.path}>
                    <ModuleCard
                      {...el}
                    />
                  </Link>
                </Grid>
              )}
            </Grid>}

            {this.Auth.getRole() != "SUPERADMIN" && <Grid container spacing={2} >
              {modulesAdmin.map((el, index) =>
                <Grid key={index} item >
                  <Link to={el.path}>
                    <ModuleCard
                      {...el}
                    />
                  </Link>
                </Grid>
              )}
            </Grid>}

            <br />
            <br />
            <br />


            <div align="center">
              <Typography
                variant="h2"
                style={{ fontSize: '2em' }}
              >
                Rangia College
                </Typography>

              <Typography
                variant="h6"
                style={{ fontSize: '0.9em' }}
              >
                ৰঙিয়া কলেজ
                </Typography>

              {this.state.version != '' ? (<Typography
                variant="caption"
                
              >
                Version: {this.state.version}
                </Typography>) : null}
            </div>

            <div align="center" className="p-3">
              <Typography
                variant="caption"
                style={{ color: `${blue[800]}`, cursor: 'pointer' }}
                onClick={this.logout}
              >
                Logout
          </Typography>

         
            </div>

          </Container>




        </div>



      </div>
    )
  }
}

const HP = withStyles(styles)(HomePage);

export default withAuth(HP);

const ModuleCard = props => {
  return (
    <div style={{
      backgroundColor: 'white',
      padding: '10px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      height: '120px',
      width: '120px',
      borderRadius: '50%',
    }}>
      <div>
        {props.icon}
      </div>

      <Typography
        variant="subtitle2"
      >
        {props.title}
      </Typography>
    </div>
  )
}