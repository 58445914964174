import { Button, Container, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import withAuth from '../auth_components/js/withAuth';
import IdPaymentLanding from '../Components/IdPayment/IdPaymentLanding';
const queryString = require('query-string');

class IdCardPaymentContainer extends Component {


    state = {
        student_id: ""
    }
    componentDidMount() {
        let ss = queryString.parse(this.props.history.location.search);
        if (ss.studentId != undefined) {
            this.setState({
                student_id: ss.studentId
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.student_id != "" && <IdPaymentLanding student_id={this.state.student_id} />}



            </div>
        )
    }
}

export default withAuth(IdCardPaymentContainer);