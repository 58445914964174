import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { Typography, Button, CardActions, Chip } from '@material-ui/core';
import CallToAction from '@material-ui/icons/CallToAction'
import { PostData } from '../../../api/service';

class OnlineMethod extends Component {
    state = {
        msg: '',
        link: '',
        amount: '',
        payment_identifier: '',
        isLoaded: false,
        encRequest: '',
        access_code: '',
        raw_data: [],
        action: '',
        feesSelected: false
    }

    componentDidMount() {
        // get the payment data now

        this.loadScript();




    }

    fetchFees = () => {
        let d = {
            student_id: this.props.data.id,
           
            payment_identifier: this.state.payment_identifier
        }


        PostData(`/getpaymentstringadminidcard`, d)
            .then((resp) => {
                console.log("🚀 ~ file: OnlineMethod.js ~ line 50 ~ OnlineMethod ~ .then ~ resp", resp)
                //console.log(resp);

                if (this.state.payment_identifier == 'CCAVENUE') {
                    if (resp.hasOwnProperty('access_code') && resp.hasOwnProperty('encRequest')) {
                        this.setState({

                            encRequest: resp.encRequest,
                            access_code: resp.access_code,
                            raw_data: resp.raw_data,
                            action: resp.action,
                            isLoaded: true
                        })
                    }
                } else if (this.state.payment_identifier == 'APEX') {
                    if (resp.hasOwnProperty('msg') && resp.hasOwnProperty('link')) {
                        this.setState({
                            msg: resp.msg,
                            link: resp.link,
                            amount: resp.amount,

                            isLoaded: true
                        })
                    }
                }


            })
    }


    loadScript = () => {
        const script = document.createElement("script");

        script.src = "https://pgi.billdesk.com/payments-checkout-widget/src/app.bundle.js";
        script.async = true;

        document.body.appendChild(script);
    }

    onBillDeskPayment = () => {
        window.bdPayment.initialize({
            msg: this.state.msg,
            options: {
                enableChildWindowPosting: true,
                enablePaymentRetry: true,
                retry_attempt_count: 2,
            },
            callbackUrl: this.state.link
        });
    }
    render() {
        return (
            <div>
                <Jumbotron>
                    <Typography
                        variant="body1"
                    >
                        You will now redirected to the payment page. Please note the following points:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body2">
                                Follow the payment procedure very carefully
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2">
                                After a successful payment, an online payment receipt will be generated
                            </Typography>
                        </li>

                        <li>
                            <Typography variant="body2">
                                Proceed the <b>"PROCEED TO PAY"</b> Button to continue
                            </Typography>
                        </li>
                    </ul>

                    {!this.state.feesSelected && <div>
                        <div>Select Payment Gateway</div>
                        <select
                            value={this.state.payment_identifier}
                            onChange={(e) => {
                                if (e.target.value == '') {
                                    this.setState({
                                        payment_identifier: '',
                                        feesSelected: false
                                    }, () => this.fetchFees())
                                } else {
                                    this.setState({
                                        payment_identifier: e.target.value,
                                        feesSelected: true
                                    }, () => this.fetchFees())
                                }
                            }}
                            className="form-control"
                        >
                            <option value="">Select</option>
                            <option value="CCAVENUE">CCAVENUE</option>
                            
                        </select>
                    </div>}


                    {this.state.feesSelected && <div>

                        {this.state.feesSelected && <div><Chip

                            label={this.state.payment_identifier}
                            color="primary"
                            onDelete={() => {
                                this.setState({
                                    payment_identifier: '',
                                    feesSelected: false
                                })
                            }}
                            variant="outlined"
                        /></div>}
                        {this.state.isLoaded && this.state.payment_identifier == 'APEX' && (<div>

                            <Button
                                variant="contained"
                                size="sm"
                                color="primary"
                                onClick={() => this.onBillDeskPayment()}
                            >
                                <CallToAction /> &nbsp; Proceed To Pay &#8377; {this.state.amount}
                            </Button>

                        </div>)}

                        {!this.state.isLoaded && `Loading .. . . Please wait`}

                        {this.state.isLoaded && this.state.payment_identifier == 'CCAVENUE' && (<form method="post" action={this.state.action}>
                            <input type="hidden" name="encRequest" value={this.state.encRequest} />
                            <input type="hidden" name="access_code" value={this.state.access_code} />
                            <CardActions>
                                <Button type="submit" disabled={!this.state.isLoaded}>
                                    Pay now
                                </Button>
                            </CardActions>
                        </form>)}
                    </div>}



                </Jumbotron>
            </div>
        );
    }
}

export default OnlineMethod;