import { Button, Card, CardContent, Container, Grid, Link, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { GetData, PostData } from '../../api/service'
import Loader from '../Loader'
import OnlineMethod from './Methods/OnlineMethod'

export default class IdPaymentLanding extends Component {

    state = {
        active: "ONLINE_PAYMENT",
        id: '',
        student: {},
        isLoaded: false,
        type: '',
        amount_paid: '',
        particulars: '',
        phone: '',
        email: '',
        isAllSet: false,
        previousRecepts: [],
        isPreviousLoaded: false
    }


    __loadPreviousPayment = (id) => {
        GetData(`/${id}/getpreviouspayments`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    previousRecepts: resp,
                    isPreviousLoaded: true
                })
            })
    }



    getPaymentMethods = (value) => {


        if (value == 'ONLINE_PAYMENT') {
            return <OnlineMethod data={this.state} user={this.props.user} history={this.props.history} />
        }

    }


    componentDidMount() {
        let id = this.props.student_id;
        if (id != undefined) {
            GetData(`/${id}/getstudentbyid`)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        isLoaded: true,
                        student: resp,
                        email: resp.email,
                        phone: resp.phone,
                        id: id
                    }, () => this.__loadPreviousPayment(this.state.id))
                })
        } else {
            this.props.history.goBack();
        }
    }

    __loadPaymentDetails = (type, id) => {
        PostData(`/getpaymentdetailsadminidcard`, { type, id })
            .then((resp) => {
                console.log("🚀 ~ file: FeesStudent.js ~ line 119 ~ FeesStudent ~ .then ~ resp", resp)
                if (resp != false) {
                    this.setState({
                        fees_id: resp.id,
                        amount_paid: resp.amount_paid,
                        particulars: resp.particulars,
                        isAllSet: true
                    })
                } else {
                    this.setState({
                        isAllSet: false
                    })
                }
            })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name == 'type') {
            if (e.target.value == 'OTHERS') {
                this.setState({
                    fees_id: 0,
                    isAllSet: true
                })
            } else {
                this.__loadPaymentDetails(e.target.value, this.state.id)
            }

        }
    }

    render() {
        return (
            <div>
                <Container>
                    <br />
                    <Typography variant='h5' gutterBottom style={{ fontWeight: 700 }}>Payment for ID Card</Typography>
                    <br />
                    {this.state.isPreviousLoaded ? (<div className="bg-light p-3">
                        {this.state.previousRecepts.length > 0 ? (<Grid container spacing={2}>
                            {this.state.previousRecepts.map((el, index) =>
                                <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                                    <Card>
                                        <CardContent>
                                            <Typography
                                                variant="caption"
                                            >
                                                {index + 1}. {el.particulars}
                                            </Typography>

                                            <Typography
                                                variant="subtitle2"
                                            >
                                                &#8377; {el.amount_paid} <Typography
                                                    variant="caption"
                                                >
                                                    Paid by {el.mop} on {el.date}
                                                </Typography>
                                            </Typography>

                                            <Link
                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                to={`/fees/receipts/${el.receipt_no}`}
                                            >
                                                <Typography variant="caption">Print</Typography>
                                            </Link>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>) : (
                            <Typography
                                variant="caption"
                            >
                                No receipts has been generated for this student earlier.
                            </Typography>
                        )}
                    </div>) : <Loader>Loading Previous Payments . . . Please wait</Loader>}


                    {this.getPaymentMethods(this.state.active)}
                </Container>
            </div>
        )
    }
}
